import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";
export function fetchWorkerList(params) {
  return axios.get(
    baseUrl + baseApiVersion + `/worker/list`,
    {
        params,
      headers,
    }
  );
}

export function fetchGuestList(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/worker/guest/list`,
    {
        params,
      headers,
    }
  );
}